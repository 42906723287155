import logo from "./logo.svg";
import "./App.css";
import { AiFillLock as LockIcon } from "react-icons/ai";

function App() {
  setTimeout(() => {
    const url = window.location.search
      ?.replace("?url=", "")
      ?.replaceAll("%3A", ":")
      ?.replaceAll("%2F", "/")
      ?.replaceAll("%26", "&")
      ?.replaceAll("%3D", "=")
      ?.replaceAll("%2C", ",");

    window.location.replace(url);
  }, 3000);

  return (
    <div className="App">
      <div className="app-loader">
        <div className="puntos">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="app-loader-text">
          <p>
            <LockIcon /> Con la tecnología de <span>qentaz</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
